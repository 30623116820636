import { IRouteConfig } from '@codeer/handypark-web-common';
import { RETRIBUTION_REGULATIONS_ROUTES } from '@features/retribution-regulations/constants';
import { lazy } from 'react';

const RetributionRegulationsPage = lazy(() =>
  import('@features/retribution-regulations/pages').then(module => ({
    default: module.RetributionRegulationsPage,
  })),
);

export const retributionRegulationsRoutes: () => IRouteConfig[] = () => [
  {
    path: RETRIBUTION_REGULATIONS_ROUTES().RETRIBUTION_REGULATIONS(),
    component: RetributionRegulationsPage,
  },

  {
    path: '*',
    redirect: '/',
  },
];
