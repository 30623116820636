import { Box } from '@chakra-ui/react';
import { TAppBoxProps } from '@codeer/handypark-web-common';
import {
  RetributionRegulationsRegion,
  TMappedMunicipalitiesToRegionsAndProvinces,
} from '@features/retribution-regulations';

type TRetributionRegulationsDataProps = TAppBoxProps & {
  data: TMappedMunicipalitiesToRegionsAndProvinces;
};

export const RetributionRegulationsData = ({ data, ...rest }: TRetributionRegulationsDataProps) => {
  return (
    <Box {...rest} gap={24}>
      {Object.entries(data).map(([region, provinces]) => {
        return <RetributionRegulationsRegion key={region} region={region} provinces={provinces} />;
      })}
    </Box>
  );
};
