import { useIsBreakpoint } from '@codeer/handypark-web-common';
import { useEffect, useState } from 'react';

export const useRegionObserver = (regions: string[]) => {
  const { isMd } = useIsBreakpoint();
  const [currentRegion, setCurrentRegion] = useState<string>('');

  useEffect(() => {
    const handleScroll = () => {
      const regionElements = regions
        .map(id => document.getElementById(id))
        .filter((el): el is HTMLElement => el !== null);

      const mostInViewElement = regionElements.reduce((mostInView: HTMLElement | null, element) => {
        const rect = element.getBoundingClientRect();
        const visibleHeight = Math.max(
          0,
          Math.min(window.innerHeight, rect.bottom) - Math.max(0, rect.top),
        );

        if (!mostInView) {
          return element;
        }

        const currentMostVisibleRect = mostInView.getBoundingClientRect();

        const mostInViewVisibleHeight = Math.max(
          0,
          Math.min(window.innerHeight, currentMostVisibleRect.bottom) -
            Math.max(0, currentMostVisibleRect.top),
        );

        return visibleHeight > mostInViewVisibleHeight ? element : mostInView;
      }, null);

      if (mostInViewElement) {
        setCurrentRegion(mostInViewElement.id);
      }
    };

    // Add scroll listener
    window.addEventListener('scroll', handleScroll, { passive: true });

    // Initial check
    handleScroll();

    // Cleanup on unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMd, regions]);

  return currentRegion;
};
