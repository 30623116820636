import { Box } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  AppContainer,
  AppDataState,
  AppTrans,
  ColoredContainer,
  PageHeaderActions,
  Paragraph,
} from '@codeer/handypark-web-common';
import { useGetMunicipalities } from '@features/municipalities';
import { RetributionRegulationsContainer } from '@features/retribution-regulations';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { mapMunicipalitiesToRegionsAndProvinces } from './retribution-regulations.helper';

const keyPrefix = 'retribution-regulations';
export const RetributionRegulations = () => {
  /* --- TRANSLATIONS --- */
  const { t } = useTranslation(T_NAMESPACES.PAGES, { keyPrefix });

  /* --- HOOKS --- */
  const { municipalities, getMunicipalitiesState } = useGetMunicipalities();

  /* --- DATA --- */
  const groupedMunicipalities = useMemo(
    () => mapMunicipalitiesToRegionsAndProvinces(municipalities ?? []),
    [municipalities],
  );

  return (
    <Box data-qa={'retribution-regulations'} display={'flex'} flexDirection={'column'} h={'full'}>
      <ColoredContainer>
        <PageHeaderActions
          showPageHeaderActionsLeft={false}
          title={t('title')}
          style={{ marginBottom: 0, padding: 0 }}
        />
      </ColoredContainer>

      <AppContainer mt={12}>
        <Paragraph>
          <AppTrans i18nKey={`${keyPrefix}.description`} ns={T_NAMESPACES.PAGES} />
        </Paragraph>
      </AppContainer>

      <AppDataState
        dataState={getMunicipalitiesState}
        fullHeightCentered
        stateProps={{ flexGrow: 1, py: 12 }}
      >
        <AppContainer my={12} display={'flex'} flexDirection={{ base: 'column', md: 'row' }}>
          <RetributionRegulationsContainer data={groupedMunicipalities} />
        </AppContainer>
      </AppDataState>
    </Box>
  );
};
