import { Box } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  AppButton,
  AppHeading,
  HEADING_STYLING_KEYS,
  Paragraph,
} from '@codeer/handypark-web-common';
import { RETRIBUTION_REGULATIONS_ROUTES } from '@features/retribution-regulations';
import { useTranslation } from 'react-i18next'; // TODO: Translate
import { useNavigate } from 'react-router-dom';

// TODO: Translate
export const LandingWhereToParkContent = () => {
  /* --- TRANSLATIONS --- */
  const { t } = useTranslation(T_NAMESPACES.PAGES, { keyPrefix: 'onboarding.where-to-park' });

  /* --- HOOKS --- */
  const navigate = useNavigate();

  return (
    <Box display={'flex'} flexDirection={'column'} gap={4}>
      <AppHeading qa={'app-heading-main-title'} as={HEADING_STYLING_KEYS.H2}>
        {t('title')}
      </AppHeading>
      <Paragraph>{t('content')}</Paragraph>
      <AppButton
        mt={4}
        onClick={() => navigate(RETRIBUTION_REGULATIONS_ROUTES().RETRIBUTION_REGULATIONS())}
      >
        {t('button')}
      </AppButton>
    </Box>
  );
};
