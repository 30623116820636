import { getProxyClient } from '@codeer/handypark-common';
import { PROXY_API_ENDPOINTS } from '@core';
import { TGetMunicipalitiesResponse } from '@features/municipalities';

export const getMunicipalities = async (): Promise<TGetMunicipalitiesResponse> => {
  const url = PROXY_API_ENDPOINTS.GET_MUNICIPALITIES();

  const { data } = await getProxyClient().get(url);

  return data ?? undefined;
};
