import { LinkProps, useStyleConfig } from '@chakra-ui/react';
import {
  AppLink,
  COMPONENT_NAMES,
  TSideNavigationLink,
  useIsBreakpoint,
} from '@codeer/handypark-web-common';
import { useScrollToElement } from '@features/retribution-regulations';
import { Link as ReactRouterLink } from 'react-router-dom';

type TRetributionRegulationsLegendLinkProps = TSideNavigationLink &
  LinkProps & {
    isActive?: boolean;
  };

export const RetributionRegulationsLegendLink = ({
  href,
  text,
  sx,
  variant,
  isActive = false,
  ...rest
}: TRetributionRegulationsLegendLinkProps) => {
  const stylesVariant = isActive ? 'active' : undefined;
  const styles = useStyleConfig(COMPONENT_NAMES.SIDE_NAVIGATION_LINK, {
    variant: variant ?? stylesVariant,
  });

  /* --- HOOKS */
  const { scrollToElement } = useScrollToElement();
  const { isMd } = useIsBreakpoint();

  return (
    <AppLink
      aria-label={'-'}
      as={ReactRouterLink}
      sx={{ ...styles, ...sx, h: 'auto', w: 'auto' }}
      href={'#'}
      onClick={e => {
        e.preventDefault();
        scrollToElement({
          elementId: href,
          offset: !isMd ? 182 /* 24 + 158 */ : 24,
        });
      }}
      {...rest}
    >
      {text}
    </AppLink>
  );
};
