import { useCallback } from 'react';

type TScrollToElementParams = {
  elementId: string;
  containerId?: string; // Defaults to 'root'
  offset?: number; // Defaults to 0
  behavior?: ScrollBehavior; // 'auto' or 'smooth', defaults to 'smooth'
};

export const useScrollToElement = () => {
  const scrollToElement = useCallback(
    ({ elementId, containerId = '', offset = 0, behavior = 'smooth' }: TScrollToElementParams) => {
      const container = document.getElementById(containerId);
      const targetElement = document.getElementById(elementId);

      if (targetElement) {
        const targetOffsetTop = targetElement.offsetTop - offset;

        (container ?? window).scrollTo({
          top: targetOffsetTop,
          behavior,
        });
      }
    },
    [],
  );

  return { scrollToElement };
};
