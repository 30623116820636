import { ImageProps } from '@chakra-ui/react';
import { Qa, T_NAMESPACES } from '@codeer/handypark-common';
import { ImagePreloader } from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next';
import landingHeroImage from './landing-hero.png?format=webp&imagetools';

type Props = Qa & ImageProps;

export const LandingHeroImage = ({ qa = 'retribution-regulations-hero-image', ...rest }: Props) => {
  const { t } = useTranslation(T_NAMESPACES.IMAGES);

  const { htmlWidth, htmlHeight } = {
    htmlWidth: 1024,
    htmlHeight: 667,
  };
  const ratio = Number((htmlWidth / htmlHeight).toFixed(2));

  const maxHeight = 17; // rem
  const maxWidth = maxHeight * ratio;

  return (
    <ImagePreloader
      image={{
        src: `${landingHeroImage}`,
        alt: t('onboarding.1'),
        htmlWidth,
        htmlHeight,

        w: 'full',

        maxHeight: `${maxHeight}rem`,
        maxWidth: `${maxWidth}rem`,

        ...rest,
      }}
      qa={qa}
    />
  );
};
