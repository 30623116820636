import { Box } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { AppHeading, HEADING_STYLING_KEYS, TAppBoxProps } from '@codeer/handypark-web-common';
import {
  currentRegionAtom,
  RetributionRegulationsLegendLink,
  TMappedMunicipalitiesToRegionsAndProvinces,
} from '@features/retribution-regulations';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

type TRetributionRegulationsLegendProps = TAppBoxProps & {
  linkContainerProps: TAppBoxProps;
  data: TMappedMunicipalitiesToRegionsAndProvinces;
};

export const RetributionRegulationsLegend = ({
  qa = 'retribution-regulations-legend',
  linkContainerProps,
  data,
  ...rest
}: TRetributionRegulationsLegendProps) => {
  /* --- TRANSLATIONS --- */
  const { t } = useTranslation(T_NAMESPACES.COMPONENTS, {
    keyPrefix: 'retribution-regulations.legend',
  });
  const { t: tRegion } = useTranslation('municipalities', { keyPrefix: 'regions' });

  /* --- ATOMS --- */
  const currentRegion = useAtomValue(currentRegionAtom);

  return (
    <Box data-qa={qa} {...rest} maxHeight={'100dvh'} overflowY={'scroll'}>
      <AppHeading as={HEADING_STYLING_KEYS.H2} textStyle={HEADING_STYLING_KEYS.H2} mb={6}>
        {t('title')}
      </AppHeading>

      <Box as={'nav'} {...linkContainerProps}>
        {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
        {Object.entries(data).map(([region, _]) => {
          return (
            <RetributionRegulationsLegendLink
              key={region}
              id={region}
              href={region}
              text={tRegion(region)}
              isActive={region === currentRegion}
            />
          );
        })}
      </Box>
    </Box>
  );
};
