import { Box } from '@chakra-ui/react';
import { TAppBoxProps } from '@codeer/handypark-web-common';
import { LandingHowToUseBlockByApp, LandingHowToUseBlockByWeb } from '@features/landing';

type TLandingHowToUseBlocksProps = TAppBoxProps;

export const LandingHowToUseBlocks = ({ ...rest }: TLandingHowToUseBlocksProps) => {
  return (
    <Box
      display={'grid'}
      gridTemplateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
      gap={12}
      {...rest}
    >
      <LandingHowToUseBlockByApp />
      <LandingHowToUseBlockByWeb />
    </Box>
  );
};
