import { ApiError, initializeApiClient, initializeProxyClient } from '@codeer/handypark-common';
import {
  DATA_STATE,
  getCombinedDataState,
  useRemoteTranslations,
} from '@codeer/handypark-web-common';
import { useMemo } from 'react';

export const useMain = () => {
  initializeApiClient({
    baseURL: '/api',
    maxRedirects: 0,
  });
  initializeProxyClient({ baseURL: '/proxy/v1', maxRedirects: 0 });

  const { hasFetchedRemoteTranslations } = useRemoteTranslations();

  const error: ApiError | undefined = useMemo(() => {
    return undefined;
  }, []);

  const dataState = useMemo(() => {
    if (!hasFetchedRemoteTranslations) {
      return DATA_STATE.LOADING;
    }
    return getCombinedDataState([]);
  }, [hasFetchedRemoteTranslations]);

  return {
    mainDataState: dataState,
    mainError: error,
  };
};
