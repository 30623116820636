import { PROXY_QUERY_KEYS } from '@core';
import { getMunicipalities } from '@features/municipalities';
import { atomWithQuery } from 'jotai-tanstack-query';

export const getMunicipalitiesAtom = atomWithQuery(() => {
  return {
    queryKey: [PROXY_QUERY_KEYS.GET_MUNICIPALITIES],
    queryFn: () => getMunicipalities(),
  };
});
