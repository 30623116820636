import { Box } from '@chakra-ui/react';
import { AppHeading, HEADING_STYLING_KEYS } from '@codeer/handypark-web-common';
import { TMunicipality } from '@features/municipalities';
import { RetributionRegulationsProvince } from '@features/retribution-regulations';
import { useTranslation } from 'react-i18next';

type TRetributionRegulationsRegionProps = {
  region: string;
  provinces: Record<string, TMunicipality[]>;
};

export const RetributionRegulationsRegion = ({
  region,
  provinces,
}: TRetributionRegulationsRegionProps) => {
  const { t: tRegion } = useTranslation('municipalities', { keyPrefix: 'regions' });

  return (
    <Box data-qa={'region'} id={region}>
      <AppHeading as={HEADING_STYLING_KEYS.H2} mb={12}>
        {tRegion(region)}
      </AppHeading>

      <Box display={'flex'} flexDirection={'column'} gap={12}>
        {Object.entries(provinces).map(([province, municipalities]) => {
          return (
            <RetributionRegulationsProvince
              key={province}
              province={province}
              municipalities={municipalities}
            />
          );
        })}
      </Box>
    </Box>
  );
};
