import { Box } from '@chakra-ui/react';
import { AppLink } from '@codeer/handypark-web-common';
import { TMunicipality } from '@features/municipalities';
import { useTranslation } from 'react-i18next';

type TRetributionRegulationsMunicipalityProps = {
  municipality: TMunicipality;
};

export const RetributionRegulationsMunicipality = ({
  municipality,
}: TRetributionRegulationsMunicipalityProps) => {
  const { t: tMunicipality } = useTranslation('municipalities');

  const { key, retributionRegulationUrl } = municipality;

  return (
    <Box>
      <AppLink aria-label={tMunicipality(key)} href={retributionRegulationUrl} qa={'municipality'}>
        {tMunicipality(key)}
      </AppLink>
    </Box>
  );
};
