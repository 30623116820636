import { IRouteConfig } from '@codeer/handypark-web-common';
import { landingRoutes } from '@features/landing';
import { retributionRegulationsRoutes } from '@features/retribution-regulations';

const devRoutes: IRouteConfig[] = [];

export const APP_ROUTES: () => IRouteConfig[] = () => [
  ...(import.meta.env.DEV ? devRoutes : []),
  ...landingRoutes(),
  ...retributionRegulationsRoutes(),
];
