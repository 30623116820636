import { Grid, GridItem } from '@chakra-ui/react';
import {
  APP_CONTAINER_VARIANTS,
  AppContainer,
  TAppContainerProps,
} from '@codeer/handypark-web-common';
import { LandingHeroContent, LandingHeroImage } from '@features/landing';

type TLandingHeroProps = TAppContainerProps;

export const LandingHero = ({ ...rest }: TLandingHeroProps) => {
  return (
    <AppContainer as={'section'} variant={APP_CONTAINER_VARIANTS.FULL_WIDTH} py={12} {...rest}>
      <Grid templateColumns='repeat(24, 1fr)' rowGap={8}>
        <GridItem aria-hidden={true} colStart={{ base: 1, md: 1 }} colEnd={{ base: 25, md: 13 }}>
          <AppContainer
            mt={{ md: 12 }}
            mb={{ md: 12 }}
            me={0}
            ms={'auto'}
            py={{ md: 6 }}
            display={'flex'}
            alignItems={'center'}
            justifyContent={{ base: 'center', md: 'flex-start' }}
            maxWidth={{ base: 'none', md: 'md' }}
          >
            <LandingHeroImage role={'presentation'} />
          </AppContainer>
        </GridItem>
        <GridItem colStart={{ base: 1, md: 13 }} colEnd={{ base: 25, md: 25 }}>
          <AppContainer
            me={'auto'}
            ms={0}
            py={{ md: 6 }}
            display={'flex'}
            flexDirection={'column'}
            gap={4}
            alignItems={'flex-start'}
            justifyContent={'center'}
            h={'full'}
            maxWidth={{ base: 'none', md: 'md' }}
          >
            <LandingHeroContent />
          </AppContainer>
        </GridItem>
      </Grid>
    </AppContainer>
  );
};
