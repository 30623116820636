import { COLOR_SCHEMES } from '@codeer/handypark-web-common';
import {
  currentRegionAtom,
  RetributionRegulationsData,
  RetributionRegulationsLegend,
  TMappedMunicipalitiesToRegionsAndProvinces,
  useRegionObserver,
} from '@features/retribution-regulations';
import { useSetAtom } from 'jotai';
import { useEffect, useMemo } from 'react';

type TRetributionRegulationsContainerProps = {
  data: TMappedMunicipalitiesToRegionsAndProvinces;
};

export const RetributionRegulationsContainer = ({
  data,
}: TRetributionRegulationsContainerProps) => {
  /* --- ATOMS --- */
  const setCurrentRegion = useSetAtom(currentRegionAtom);

  /* --- HOOKS --- */
  const regions = useMemo(() => {
    return Object.keys(data);
  }, [data]);
  const currentRegion = useRegionObserver(regions);

  /* --- ACTIONS */
  useEffect(() => {
    setCurrentRegion(currentRegion);
  }, [currentRegion, setCurrentRegion]);

  // TODO: Create theme files for these components
  return (
    <>
      <RetributionRegulationsData
        data={data}
        w={'full'}
        flexGrow={{ base: 1, md: 5 }}
        display={'flex'}
        flexDirection={'column'}
        gap={12}
        pt={6}
        pb={6}
        pr={{ md: 12 }}
      />
      <RetributionRegulationsLegend
        data={data}
        order={{ base: -1, md: 1 }}
        flexGrow={1}
        minWidth={'2xs'}
        w={{ base: 'full', md: 'unset' }}
        pl={{ base: 0, md: 12 }}
        pr={{ base: 0, md: 6 }}
        pt={{ base: 6, md: 6 }}
        pb={6}
        borderLeft={{ base: 'none', md: '0.0625em solid' }}
        borderLeftColor={`${COLOR_SCHEMES.SHADE}.300`}
        borderBottom={{ base: '0.0625em solid', md: 'none' }}
        borderBottomColor={`${COLOR_SCHEMES.SHADE}.300`}
        position={'sticky'}
        top={0}
        background={`${COLOR_SCHEMES.BODY}.background`}
        alignSelf={'flex-start'}
        linkContainerProps={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: { base: 'row', md: 'column' },
          gap: 4,
        }}
      />
    </>
  );
};
