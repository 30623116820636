// import '@codeer/handypark-common/fonts/Figtree/figtree.font.scss';

import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import { TGeneralContextProviderProps } from '@codeer/handypark-web-common';
import { HeadTags, theme } from '@core';
import { Provider as JotaiProvider } from 'jotai';

import { FC } from 'react';

export const MainProvider: FC<TGeneralContextProviderProps> = ({ children }) => {
  if (HP.SHOW_DEV_SETTINGS) {
    console.log({ theme: theme() });
  }

  return (
    <ChakraProvider theme={theme()}>
      <CSSReset />
      <JotaiProvider>
        <HeadTags />
        {children}
      </JotaiProvider>
    </ChakraProvider>
  );
};
