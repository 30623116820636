export const MUNICIPALITY_PROVINCES = Object.freeze({
  ANTWERPEN: 'Antwerpen',
  OOST_VLAANDEREN: 'OostVlaanderen',
  WEST_VLAANDEREN: 'WestVlaanderen',
  VLAAMS_BRABANT: 'VlaamsBrabant',
  LIMBURG: 'Limburg',
  HENEGOUWEN: 'Henegouwen',
  LUIK: 'Luik',
  LUXEMBURG: 'Luxemburg',
  NAMEN: 'Namen',
  WAALS_BRABANT: 'WaalsBrabant',
  BRUSSELS_HOOFDSTEDELIJK_GEWEST: 'BrusselsHoofdStedelijkGewest',
});
