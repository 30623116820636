import {
  AppContainerLayout,
  AppFooter,
  AppSkipNavLink,
  DevSettings,
  DevSettingsWrapper,
  Header,
  TAppPageLayoutProps,
} from '@codeer/handypark-web-common';

export const PageLayout = ({ children }: Omit<TAppPageLayoutProps, 'qa'>) => {
  /*
   * DesktopNav is situated in the header
   * MobileNav is situated above the footer
   * */

  return (
    <DevSettingsWrapper>
      <AppSkipNavLink />
      <Header qa={'header'} links={[]} showLanguageSelect={true} />
      {HP?.SHOW_DEV_SETTINGS === true && (
        <DevSettings showDarkModeToggle={true} showTranslationToggle={true} />
      )}
      <AppContainerLayout>{children}</AppContainerLayout>
      <AppFooter isContentMediumWidth />
    </DevSettingsWrapper>
  );
};
