import {
  mapProvinceToRegion,
  TMunicipality,
  TMunicipalityProvince,
  TMunicipalityRegion,
} from '@features/municipalities';

export type TMappedMunicipalitiesProvinces = Record<TMunicipalityProvince, TMunicipality[]>;
export type TMappedMunicipalitiesToRegionsAndProvinces = Record<
  TMunicipalityRegion,
  TMappedMunicipalitiesProvinces
>;

export const mapMunicipalitiesToRegionsAndProvinces = (municipalities: TMunicipality[]) => {
  return municipalities.reduce<TMappedMunicipalitiesToRegionsAndProvinces>(
    (groups, municipality) => {
      const region = mapProvinceToRegion(municipality.province); // Map province to region
      const { province } = municipality;

      // Ensure region exists
      if (!groups[region]) {
        groups[region] = {} as TMappedMunicipalitiesProvinces;
      }

      // Ensure province exists within the region
      if (!groups[region][province]) {
        groups[region][province] = [];
      }

      // Add municipality to the corresponding province
      groups[region][province].push(municipality);
      return groups;
    },
    {} as TMappedMunicipalitiesToRegionsAndProvinces,
  );
};
