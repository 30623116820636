import { ApiError } from '@codeer/handypark-common';
import { checkIsFetching, useDataState } from '@codeer/handypark-web-common';
import { getMunicipalitiesAtom } from '@features/municipalities';
import { useAtomValue } from 'jotai';

export const useGetMunicipalities = () => {
  const { fetchStatus, isPending, error, data, refetch } = useAtomValue(getMunicipalitiesAtom);
  const isFetching = checkIsFetching(fetchStatus);

  const getMunicipalitiesState = useDataState({ isLoading: isFetching || isPending, error });

  return {
    getMunicipalitiesState,
    municipalities: data,
    isLoadingMunicipalities: isFetching,
    getMunicipalitiesError: error as unknown as ApiError | undefined,
    refetchMunicipalities: refetch,
  };
};
