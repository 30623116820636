import { Box } from '@chakra-ui/react';
import { AppHeading, HEADING_STYLING_KEYS } from '@codeer/handypark-web-common';
import { TMunicipality } from '@features/municipalities';
import { RetributionRegulationsMunicipality } from '@features/retribution-regulations';
import { useTranslation } from 'react-i18next';

type TRetributionRegulationsProvinceProps = {
  province: string;
  municipalities: TMunicipality[];
};

export const RetributionRegulationsProvince = ({
  province,
  municipalities,
}: TRetributionRegulationsProvinceProps) => {
  const { t: tProvince } = useTranslation('municipalities', { keyPrefix: 'provinces' });

  return (
    <Box data-qa={'province'}>
      <AppHeading as={HEADING_STYLING_KEYS.H3} mb={8}>
        {tProvince(province)}
      </AppHeading>

      <Box columnGap={8} sx={{ columnCount: { base: 1, sm: 2, lg: 3 }, columnGap: 8 }}>
        {municipalities.map(municipality => {
          return (
            <RetributionRegulationsMunicipality
              key={municipality.key}
              municipality={municipality}
            />
          );
        })}
      </Box>
    </Box>
  );
};
